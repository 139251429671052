@import "variables";

/* Base */

*,
::after,
::before {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

html {
  min-height: 100%;
  display: flex;
}

body {
  min-height: 100vh;
  flex: 1;
}

[hidden] {
  display: none !important;
}

body {
  font-family: var(--font-family);
  background-color: var(--surface-ground);
  color: var(--text-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

:focus {
  outline: 2px solid var(--primary-100);
}

/* PrimeNG */

.p-badge {
  background: var(--primary-color);
  color: var(--primary-color-text);
}

.p-datatable {
  .p-datatable-tbody {
    > tr {
      > td {
        padding: 0.5rem;

        .p-button-sm {
          margin: -0.25rem 0;
        }
      }
    }
  }
}

.p-button {
  // padding: 0.5rem 1rem;
  background: var(--primary-color);
  border: var(--primary-color);
  border-radius: var(--border-radius);
  color: var(--primary-color-text);

  .p-button-label {
    white-space: nowrap;
  }

  .p-button-link {
    color: var(--primary-color);

    :enabled:hover {
      color: var(--primary-color);
    }
  }

  &.p-button-sm {
    &.p-button-icon-only {
      width: 2.4rem !important;

      &.p-button-rounded {
        height: 2.4rem !important;
      }
    }
  }

  &.p-button-xs {
    font-size: 0.75rem !important;
    padding: 0.531rem 0.681rem !important;

    .p-button-icon {
      font-size: 0.75rem !important;
    }

    &.p-button-icon-only {
      width: 1.4rem !important;

      &.p-button-rounded {
        height: 1.4rem !important;
      }
    }
  }
}

.p-inputtext {
  border-radius: var(--border-radius);
  outline: none !important;
  box-shadow: none !important;
  border-color: var(--text-color-secondary);
  padding: 0.5rem 0.5rem;
}

.p-dialog {
  border-radius: var(--border-radius);

  .p-dialog-header {
    border-top-right-radius: var(--border-radius);
    border-top-left-radius: var(--border-radius);
  }

  .p-dialog-content:last-of-type {
    border-bottom-right-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
  }

  .p-dialog-footer {
    border-bottom-right-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
  }
}

.p-slidemenu .p-menuitem-link {
  .p-menuitem-icon,
  .p-menuitem-text {
    color: var(--text-color);
  }

  &:not(.p-disabled):hover {
    background: var(--primary-50);

    .p-menuitem-icon,
    .p-menuitem-text {
      color: var(--primary-color);
    }
  }
}

.p-toast .p-toast-message {
  border-radius: var(--border-radius);
}

// BUG: https://github.com/primefaces/primeng/issues/10012
.p-toast-message-text {
  overflow-wrap: break-word;
  max-width: 18.5rem;
}

.p-dropdown {
  border-radius: var(--border-radius);
  border-color: var(--text-color-secondary);
  outline: none !important;
  box-shadow: none !important;

  .p-dropdown-label {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .p-dropdown-trigger {
    width: 2rem;
  }
}

.p-dropdown-panel {
  border-radius: var(--border-radius);

  .p-dropdown-items {
    .p-dropdown-item {
      padding: 0.5rem 1rem;
    }
  }
}

.p-multiselect {
  border-radius: var(--border-radius);
  border-color: var(--text-color-secondary);
  outline: none !important;
  box-shadow: none !important;

  .p-multiselect-label {
    padding: 0.5rem 0.5rem;
  }

  .p-multiselect-trigger {
    width: 2rem;
  }
}

.p-multiselect-panel {
  border-radius: var(--border-radius);

  .p-multiselect-items {
    .p-multiselect-item {
      padding: 0.5rem 1rem;
    }
  }
}

.p-paginator {
  border: none;
  padding: 0.25rem 0.5rem;

  .p-paginator-first,
  .p-paginator-prev,
  .p-paginator-next,
  .p-paginator-last {
    min-width: 2rem;
    height: 2.4rem;
  }

  .p-paginator-pages {
    .p-paginator-page {
      min-width: 2.4rem;
      height: 2.4rem;
      outline: none !important;
      box-shadow: none !important;
    }
  }

  .p-dropdown {
    border-color: #ced4da;
    height: 2.813rem;
  }
}

.p-checkbox {
  .p-checkbox-box {
    border-radius: var(--border-radius);
    &.p-highlight {
      background: var(--primary-color);
      border: var(--primary-color);
      color: var(--primary-color-text);
    }
  }
}

.p-disabled, .p-component:disabled, input:disabled {
  opacity: 1;
  cursor: default;
  background-color: var(--gray-50);
  color: var(--gray-400);
  border-color: var(--gray-400);
}

.ic{
  background-repeat: no-repeat;
  background-size: 10px;
  width: 10px;
  height: 10px;
  cursor: pointer;
}
.ic-flag-goal:before{
  background:url('/src/assets/icons/flag.png');
}
.ic-flag-goal:after{
  background:url('/src/assets/icons/flag.png');
}
.ic-flag-goal{
  background:url('/src/assets/icons/flag.png');
}


/* Main */

.main-layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;

  &__content-wrapper {
    display: flex;
    padding-top: 84px;
    flex: 1 1 0;
  }

  &__content {
    padding: 1rem 1rem 0.5rem 1rem;
    flex: 1 1 0;
    // height: 100% !important;

    // > * {
    //   height: 100% !important;
    // }
  }

  &__footer {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 1rem 1rem 0.5rem 1rem;
  }
}
