:root {
  --text-color: #130500;
  --text-color-secondary: #3d3d3d;
  --primary-color: red;
  --primary-color-text: #ffffff;
  --border-radius: 0;
  --focus-ring: 0 0 0 0.2rem #cfe0fc;

  --blue-50: #f2f7ff;
  --blue-100: #e6f0ff;
  --blue-200: #cfe0fc;
  --blue-300: #a0c0fa;
  --blue-400: #5c92f7;
  --blue-500: #267aff;
  --blue-600: #0062ff;
  --blue-700: #005aff;
  --blue-800: #0046ff;
  --blue-900: #0034ff;

  --primary-50: #f2f7ff;
  --primary-100: #e6f0ff;
  --primary-200: #cfe0fc;
  --primary-300: #a0c0fa;
  --primary-400: #5c92f7;
  --primary-500: #267aff;
  --primary-600: #0062ff;
  --primary-700: #005aff;
  --primary-800: #0046ff;
  --primary-900: #0034ff;

  --surface-0: #ffffff;
  --surface-50: #fafafa;
  --surface-100: #f5f5f5;
  --surface-200: #eeeeee;
  --surface-300: #dcdcdc;
  --surface-400: #bebebe;
  --surface-500: #9e9e9e;
  --surface-600: #757575;
  --surface-700: #6f6f6f;
  --surface-800: #3d3d3d;
  --surface-900: #130500;

  --gray-50: #fafafa;
  --gray-100: #f5f5f5;
  --gray-200: #eeeeee;
  --gray-300: #dcdcdc;
  --gray-400: #bebebe;
  --gray-500: #9e9e9e;
  --gray-600: #757575;
  --gray-700: #6f6f6f;
  --gray-800: #3d3d3d;
  --gray-900: #130500;


  --surface-ground: #f4f7fc;
  --surface-section: #ffffff;
  --surface-card: #ffffff;
  --surface-overlay: #ffffff;
  --surface-border: #cfe0fc;
  --surface-hover: #f2f7ff;
}

// Custom spacing
$primaryColor: #61cd0e;
$buttonPadding: 5rem 1rem;
$fontSize: 3rem;

//primary #0062ff
//accent #ff2cb7
//warn #ff3719
